import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
    ThemeProvider,
    Grid,
    Cell,
} from '@saladbob/sassafras';

import Home from './views/Home';
import About from './views/About';
import Apply from './views/Apply';
import Faq from './views/Faq';
import Contact from './views/Contact';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import PrivacyCa from './views/PrivacyCa';
import Header from './components/Header';
import HeaderSteps from './components/HeaderSteps';
import Footer from './components/Footer';
import Nav from './components/Nav';
import ScrollToTop from './components/ScrollToTop';
import theme from './theme';

import './sass/app.scss';

const useQuery = (location) => new URLSearchParams(location.search);

const qString = useQuery(window.location);

const affiliateId = qString.get('affiliate_id');
const affiliate = qString.get('affiliate');
const s1 = qString.get('s1');
const s2 = qString.get('s2');
const s3 = qString.get('s3');

const nav = [{
    key: 'home',
    link: '/',
    label: 'Home',
}, {
    key: 'about',
    link: '/about',
    label: 'About',
}, {
    key: 'contact',
    link: '/contact',
    label: 'Contact Us',
}];

const SiteRoutes = ({
    brandName,
    publicPath,
    imagePath,
    brandFolder,
    footerNav,
    apiEndpoint,
    apiFormFields,
    apiContact,
}) => {
    const navigate = useNavigate();

    const location = useLocation();

    const [loading, setLoading] = useState();

    const query = useQuery(location);
    const qs = Number(query.get('step'));

    // Header progress steps
    const hStep = qs || 0;

    const [userData, setUserData] = useState({
        affiliate_id: affiliateId,
        affiliate,
        s1,
        s2,
        s3,
    });
    const [step, setStep] = useState(qs);
    const [errMsg, setErrMsg] = useState();
    const [contactSubmitted, setContactSubmitted] = useState(false);
    const [headerStep, setHeaderStep] = useState(hStep);
    const [lastStep, setLastStep] = useState(0);
    const [formFields, setFormFields] = useState({});

    const appendData = (formData) => {
        const data = {};

        for (const key of formData.keys()) {
            data[key] = formData.get(key);
        }

        return data;
    };

    const ownerData = (data, owner) => ({
        full_name: `${data[`owner_${owner}_first_name`]} ${data[`owner_${owner}_last_name`]}`,
        first_name: data[`owner_${owner}_first_name`],
        last_name: data[`owner_${owner}_last_name`],
        home_phone: data[`owner_${owner}_business_phone`],
        cell_phone: data[`owner_${owner}_cell_phone`],
        personal_email: data[`owner_${owner}_business_email`],
        home_address: data[`owner_${owner}_home_address`],
        city: data[`owner_${owner}_city`],
        state_id: data[`owner_${owner}_state`],
        zip: data[`owner_${owner}_zip`],
    });

    const parseOwners = (data) => {
        const count = data.owner_count;
        const owners = [];
        for (let i = 0; i < count; i += 1) {
            owners.push(ownerData(data, i + 1));
        }
        return owners;
    };

    function submitContact(form) {
        const formData = new FormData(form);

        setLoading(true);

        fetch(apiContact, {
            body: formData,
            method: 'POST',
        })
            .then((res) => res.json())
            .then((res) => {
                if (Number(res.success) === 1) {
                    setContactSubmitted(true);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setErrMsg(res.msg);
                }
            });
    }

    function submitForm(form, d) {
        const newData = appendData(new FormData(form));

        const data = {
            ...d,
            ...newData,
        };

        const owners = parseOwners(data);

        const today = new Date();

        const payload = {
            merchant: {
                company: data.business_name,
                first_name: owners[0].first_name,
                phone: owners[0].home_phone,
                last_name: owners[0].last_name,
                email: owners[0].personal_email,
                mobile_phone: owners[0].cell_phone,
                state_id: owners[0].state_id,
                business_time_id: data.length,
                use_of_funds_id: data.loan_purpose,
                requested_loan_id: data.amount,
                legal_entity_id: data.entity_type,
                submitted_date: `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`,
                optin: data.optin,
                monthly_gross_sale_id: data.deposits,
            },
            order: '',
            business: {
                legal_corporate_name: data.business_name,
                phone: '',
                website: '',
                business_address: '',
                city: '',
                state_id: '',
                zip: data.zip,
                fax: '',
                email: '',
            },
            business_location: [{
                full_address: '',
                city: '',
                state_id: '',
                zip: data.zip,
                property_situation: '',
                monthly_rent: '',
            }],
            business_details: {
                business_time_id: data.length,
                legal_entity_id: data.entity_type,
            },
            cash_advances: [{
                current_funder: '',
                advance_date: '',
                loan_amount: '',
                total_payback: '',
                daily_payback: '',
                balance_remaining: '',
            }],
            trade_references: [{
                name: '',
                contact: '',
                phone: '',
            }],
            owners,
            disclosure: {
                bank_name: '',
                bank_address: '',
                bank_contact_person: '',
                account_number: '',
                city: '',
                phone: '',
                routing: '',
                state_id: '',
                bank_username: '',
                account_type: '',
                zip: '',
                bank_password: '',
                bank_average_monthly_deposits: '',
            },
            loan_applications: [{
                requested_amount_id: data.amount,
                proposed_use_of_funds: data.loan_purpose,
                loan_application_status_id: '',
            }],
            affiliate_id: data.affiliate_id,
            affiliate: data.affiliate,
            s1: data.s1,
            s2: data.s2,
            s3: data.s3,
        };

        setLoading(true);

        fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            referrerPolicy: 'no-referrer-when-downgrade',
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    setLoading(false);
                    setLastStep(Math.max(4, lastStep));
                    navigate('/apply?step=4');
                } else if (res.redirect_to) {
                    window.location.href = res.redirect_to;
                } else {
                    setLoading(false);
                    setErrMsg(res.note);
                }
            });
    }

    function onSubmitStep1(form) {
        const newData = appendData(new FormData(form));

        const data = {
            ...userData,
            ...newData,
        };

        setUserData(data);

        setLastStep(Math.max(2, lastStep));
        navigate('/apply?step=1');
    }

    function onSubmitStep2(form) {
        const newData = appendData(new FormData(form));

        const data = {
            ...userData,
            ...newData,
        };

        setUserData(data);

        setLastStep(Math.max(2, lastStep));
        navigate('/apply?step=2');
    }

    function onSubmitStep3(form) {
        const newData = appendData(new FormData(form));

        const data = {
            ...userData,
            ...newData,
        };

        setUserData(data);
        setLastStep(Math.max(3, lastStep));
        navigate('/apply?step=3');
    }

    function onSubmitStep4(form) {
        submitForm(form, userData);
    }

    function stepBack() {
        const gotoStep = Math.max(0, step - 1);

        navigate(`/apply?step=${gotoStep}`);
    }

    useEffect(() => {
        const qstep = Number(query.get('step'));

        /*
        if (qstep < 4 && qstep > 1 && qstep > lastStep) {
            return history.replace(`/apply?step=${lastStep}`);
        }
        */

        setStep(qstep || 0);

        return setHeaderStep(qstep || 0);
    }, [location]);

    useEffect(() => {
        fetch(apiFormFields, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => {
                setFormFields(res);
            });
    }, []);

    return (
        <Grid
            columns="3fr minmax(0px, max-content)"
            rows="minmax(max-content, max-content) 1fr auto"
            area="'header header' 'main sidebar' 'footer footer'"
            height="100vh"
        >
            <Cell area="header">
                <Routes>
                    <Route
                        path="/*"
                        element={(
                            <Header
                                logo={`${publicPath}${imagePath}${brandFolder}logo.svg`}
                                nav={(<Nav nav={nav} color="neutral800" font="title" />)}
                                navMobile={(<Nav nav={nav} color="white" font="leader" vertical />)}
                                bgColor="white"
                            />
                        )}
                    />
                    <Route
                        path="/apply"
                        element={(
                            <HeaderSteps
                                logo={`${publicPath}${imagePath}${brandFolder}logo-icon.svg`}
                                bgColor="white"
                                step={headerStep}
                            />
                        )}
                    />
                </Routes>
            </Cell>
            <Cell area="main">
                <Routes>
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/privacy-policy-ca" element={<PrivacyCa />} />
                    <Route path="/about" element={<About imgPath={`${publicPath}${imagePath}${brandFolder}`} />} />
                    <Route
                        path="/contact"
                        element={<Contact onSubmit={submitContact} completed={contactSubmitted} loading={loading} />}
                    />
                    <Route path="/faq" element={<Faq />} />
                    <Route
                        path="/apply"
                        element={(
                            <Apply
                                brandName={brandName}
                                formFields={formFields}
                                step={step}
                                imgPath={`${publicPath}${imagePath}${brandFolder}`}
                                onSubmitStep1={onSubmitStep1}
                                onSubmitStep2={onSubmitStep2}
                                onSubmitStep3={onSubmitStep3}
                                onSubmitStep4={onSubmitStep4}
                                userData={userData}
                                stepBack={stepBack}
                                loading={loading}
                                errMsg={errMsg}
                            />
                        )}
                    />
                    <Route
                        path="/"
                        element={(
                            <Home
                                brandName={brandName}
                                imgPath={`${publicPath}${imagePath}${brandFolder}`}
                            />
                        )}
                    />
                </Routes>
            </Cell>
            <Cell area="footer">
                <Footer
                    brandName="Green Financial"
                    nav={(<Nav nav={footerNav} color="white" font="small" weight="bold" />)}
                />
            </Cell>
        </Grid>
    );
};

const App = ({ basename, reCaptchaKey }) => (
    <Router basename={basename}>
        <ScrollToTop />
        <GoogleReCaptchaProvider
            reCaptchaKey={reCaptchaKey}
        >
            <ThemeProvider theme={theme}>
                <SiteRoutes />
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    </Router>
);

SiteRoutes.propTypes = {
    brandName: PropTypes.string,
    publicPath: PropTypes.string,
    imagePath: PropTypes.string,
    brandFolder: PropTypes.string,
    apiEndpoint: PropTypes.string,
    apiFormFields: PropTypes.string,
    apiContact: PropTypes.string,
    footerNav: PropTypes.arrayOf(PropTypes.object),
};

SiteRoutes.defaultProps = {
    apiEndpoint: window.apiEndpoint || '',
    apiFormFields: window.apiFormFields || '',
    apiContact: window.apiContact || '/api/contact',
    brandName: window.brandName || 'Green Financial',
    publicPath: window.publicPath || 'public/',
    imagePath: window.imagePath || 'images/',
    brandFolder: window.brandFolder || '',
    footerNav: window.footerNav || [{}],
};

App.propTypes = {
    basename: PropTypes.string,
    reCaptchaKey: PropTypes.string,
};

App.defaultProps = {
    basename: window.basename || '/',
    reCaptchaKey: window.reCaptchaKey || '',
};

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
