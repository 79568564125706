import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
    ArrowLeftCircle,
    ArrowRightCircle,
    Loader,
} from 'react-feather';

import {
    Type,
    ValidationForm,
    FieldText,
    FieldSelect,
    Btn,
    BtnText,
    Box,
    Grid,
} from '@saladbob/sassafras';

const FormStep2 = ({
    legalEntities,
    businessTimes,
    onSubmit,
    stepBack,
    userData,
    loading,
    errMsg,
    submitBtnColor,
    submitBtnHoverColor,
    submitBtnTxtColor,
}) => {
    const formRef = useRef();

    function validate() {
        formRef.current.validate();
    }

    return (
        <Box>
            {errMsg && (
                <Type tag="h2" font="h3" margin={[0, 0, 'md', 0]} color="white">
                    {errMsg}
                </Type>
            )}
            <ValidationForm ref={formRef} onSubmit={onSubmit}>
                <FieldText
                    name="business_name"
                    label={(<Type font="leader" color="white">What is your legal business name:</Type>)}
                    value={userData.business_name}
                    margin={[0, 0, 'md', 0]}
                    borderColor={false}
                    rules={{ required: true }}
                />

                <FieldText
                    name="zip"
                    label={(<Type font="leader" color="white">What is the business zip code</Type>)}
                    value={userData.zip}
                    margin={[0, 0, 'md', 0]}
                    borderColor={false}
                    type="tel"
                    rules={{ required: true }}
                />

                <FieldSelect
                    name="entity_type"
                    label={(<Type font="leader" color="white">What is your Legal Business Structure:</Type>)}
                    placeholder="--Select--"
                    value={userData.entity_type}
                    margin={[0, 0, 'md', 0]}
                    borderColor={false}
                    options={legalEntities}
                    rules={{ required: true }}
                />

                <FieldSelect
                    name="length"
                    label={(<Type font="leader" color="white">How long have you been in business:</Type>)}
                    placeholder="--Select--"
                    value={userData.length}
                    margin={[0, 0, 'xl', 0]}
                    borderColor={false}
                    options={businessTimes}
                    rules={{ required: true }}
                />
            </ValidationForm>
            <Grid columns={{ default: '1fr 6fr', laptop: '1fr 1fr' }} justify="space-between" align="center">
                <BtnText
                    font="h3"
                    txtColor="primary"
                    outline
                    size="xl"
                    onClick={stepBack}
                >
                    <ArrowLeftCircle size="32" />
                </BtnText>

                <Btn
                    font="h3"
                    type="button"
                    color={submitBtnColor}
                    hoverColor={submitBtnHoverColor}
                    txtColor={submitBtnTxtColor}
                    size="lg"
                    iconRight={(<ArrowRightCircle size="32" />)}
                    onClick={validate}
                    loading={loading}
                    iconLoading={(<Loader size="32" />)}
                >
                    CONTINUE
                </Btn>
            </Grid>
        </Box>
    );
};

FormStep2.propTypes = {
    onSubmit: PropTypes.func,
    stepBack: PropTypes.func,
    userData: PropTypes.object,
    loading: PropTypes.bool,
    errMsg: PropTypes.string,
    submitBtnColor: PropTypes.string,
    submitBtnHoverColor: PropTypes.string,
    submitBtnTxtColor: PropTypes.string,
    legalEntities: PropTypes.object,
    businessTimes: PropTypes.object,
};

FormStep2.defaultProps = {
    userData: {},
};

export default FormStep2;
