import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Box, ThemeContext } from '@saladbob/sassafras';

const styles = createUseStyles({
    Dot: {
        borderRadius: '100%',
        display: 'block',
        margin: '0.5rem auto',
        background: ({ theme, color }) => theme.colors[color],
    },
});

const DotttedBorder = ({
    color, margin, mt, me, mb, ms,
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, color });

    return (
        <Box margin={margin} mt={mt} me={me} mb={mb} ms={ms} style={{ width: 'auto' }}>
            <div className={classes.Dot} style={{ width: 4, height: 4 }} />
            <div className={classes.Dot} style={{ width: 4, height: 4 }} />
            <div className={classes.Dot} style={{ width: 6, height: 6 }} />
            <div className={classes.Dot} style={{ width: 7, height: 7 }} />
            <div className={classes.Dot} style={{ width: 7, height: 7 }} />
            <div className={classes.Dot} style={{ width: 6, height: 6 }} />
            <div className={classes.Dot} style={{ width: 4, height: 4 }} />
            <div className={classes.Dot} style={{ width: 4, height: 4 }} />
        </Box>
    );
};

DotttedBorder.propTypes = {
    color: PropTypes.string,
    margin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
    ]),
    mt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
    ]),
    me: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
    ]),
    mb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
    ]),
    ms: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
    ]),
};

DotttedBorder.defaultProps = {};

export default DotttedBorder;
