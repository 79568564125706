import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { ThemeContext } from '@saladbob/sassafras';

const styles = createUseStyles({
    Container: {
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
        borderRadius: '100%',
        backgroundImage: ({ image }) => `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
});

const ImageCircle = ({
    image,
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, image });

    return (
        <div className={classes.Container} />
    );
};

ImageCircle.propTypes = {
    image: PropTypes.string,
};

ImageCircle.defaultProps = {};

export default ImageCircle;
