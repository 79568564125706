import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
    ArrowLeftCircle,
    ArrowRightCircle,
    Loader,
} from 'react-feather';

import {
    Type,
    ValidationForm,
    FieldSelect,
    FieldRadio,
    Btn,
    BtnText,
    Box,
    Grid,
} from '@saladbob/sassafras';

const radioOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

const FormStep3 = ({
    monthlyDeposits,
    onSubmit,
    stepBack,
    userData,
    loading,
    submitBtnColor,
    submitBtnHoverColor,
    submitBtnTxtColor,
}) => {
    const formRef = useRef();

    function validate() {
        formRef.current.validate();
    }

    return (
        <Box>
            <ValidationForm ref={formRef} onSubmit={onSubmit}>
                <FieldRadio
                    name="bank_account"
                    label={(
                        <Type font="p" color="white">
                            Do you have a Business Bank Account in the same name as the Registered Business Name:
                        </Type>
                    )}
                    value={userData.bank_account}
                    options={radioOptions}
                    margin={[0, 0, 'md', 0]}
                    columns="minmax(max-content, 200px) minmax(max-content, 200px)"
                    uncheckedColor="primary200"
                    uncheckedOutline
                    checkedColor="primary400"
                    rules={{ required: true }}
                />

                <FieldSelect
                    name="deposits"
                    label={(
                        <Type font="p" color="white" mb="xs">
                            What are your average monthly deposits into your Business Bank Account:
                        </Type>
                    )}
                    placeholder="--Select--"
                    value={userData.deposits}
                    margin={[0, 0, 'md', 0]}
                    borderColor={false}
                    options={monthlyDeposits}
                    rules={{ required: true }}
                />
            </ValidationForm>
            <Grid columns={{ default: '1fr 6fr', laptop: '1fr 1fr' }} justify="space-between" align="center">
                <BtnText
                    font="h3"
                    txtColor="primary"
                    outline
                    size="xl"
                    onClick={stepBack}
                >
                    <ArrowLeftCircle size="32" />
                </BtnText>

                <Btn
                    font="h3"
                    type="button"
                    color={submitBtnColor}
                    hoverColor={submitBtnHoverColor}
                    txtColor={submitBtnTxtColor}
                    size="lg"
                    iconRight={(<ArrowRightCircle size="32" />)}
                    onClick={validate}
                    loading={loading}
                    iconLoading={(<Loader size="32" />)}
                >
                    CONTINUE
                </Btn>
            </Grid>
        </Box>
    );
};

FormStep3.propTypes = {
    onSubmit: PropTypes.func,
    stepBack: PropTypes.func,
    userData: PropTypes.object,
    loading: PropTypes.bool,
    submitBtnColor: PropTypes.string,
    submitBtnHoverColor: PropTypes.string,
    submitBtnTxtColor: PropTypes.string,
    monthlyDeposits: PropTypes.object,
};

FormStep3.defaultProps = {
    userData: {},
};

export default FormStep3;
