import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Box, ThemeContext } from '@saladbob/sassafras';

const styles = createUseStyles({
    Container: {
        width: '100%',
        height: '100%',
        paddingBottom: '100%',
        position: 'relative',
    },
    Mask: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: '0 0 50%',
        overflow: 'hidden',
        backgroundImage: ({ image }) => `url(${image})`,
        backgroundPosition: 'right',
        backgroundSize: 'cover',
    },
    Line1: {
        width: '90%',
        height: '90%',
        position: 'absolute',
        right: '-1rem',
        top: '1rem',
        border: ({ theme }) => `0.5rem solid ${theme.colors.secondary}`,
        borderRadius: '50% 0 50%',
    },
});

const ImageLines2 = ({
    image,
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, image });

    return (
        <Box height="100%">
            <div className={classes.Container}>
                <div className={classes.Line1} />
                <div className={classes.Mask} />
            </div>
        </Box>
    );
};

ImageLines2.propTypes = {
    image: PropTypes.string,
};

ImageLines2.defaultProps = {};

export default ImageLines2;
