import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Type, Box, ThemeContext } from '@saladbob/sassafras';

const styles = createUseStyles({
    Nav: {
        width: 'auto',
        flexDirection: ({ vertical }) => (vertical ? 'column' : 'row'),
    },
    Item: {
        padding: ({ theme, vertical }) => (vertical ? [[theme.spacing.md, 0]] : [[0, theme.spacing.md]]),
        '& a': {
            textDecoration: 'none',
        },
    },
});

const Nav = ({
    nav, font, color, weight, vertical,
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, vertical });

    const mapNav = () => nav.map((item) => (
        <div key={item.key} className={classes.Item} style={{ textAlign: 'center' }}>
            { item.href && (
                <Type
                    tag="a"
                    href={item.href}
                    color={color}
                    font={font}
                    target={item.target}
                    weight={weight}
                >
                    {item.label}
                </Type>
            )}
            { item.link && (
                <Link
                    to={item.link}
                >
                    <Type
                        tag="span"
                        color={color}
                        font={font}
                        target="_blank"
                        weight={weight}
                        decoration={false}
                    >
                        {item.label}
                    </Type>
                </Link>
            )}
        </div>
    ));

    return (
        <Box display={{ default: 'block', laptop: 'flex' }} className={classes.Nav} width="auto">
            {mapNav()}
        </Box>
    );
};

Nav.propTypes = {
    nav: PropTypes.arrayOf(PropTypes.object),
    font: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    vertical: PropTypes.bool,
};

Nav.defaultProps = {};

export default Nav;
