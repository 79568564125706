import React from 'react';

import {
    H2,
    P,
    Type,
    Box,
    Container,
    Grid,
    Cell,
} from '@saladbob/sassafras';

import { Link } from 'react-router-dom';

const FaqPanel = () => (
    <Box bgColor="neutral50" padding={['xl', 'lg']}>
        <Box height="80px" bgColor="primary400" maxWidth="1100px" ms="auto" me="auto" />
        <Container size="lg" bgColor="white" padding="lg" mt="-60px" ms="auto" me="auto">
            <H2 font="h2" mb="sm" align="center" weight="bold">
                Have questions about Green Financial?
            </H2>
            <P font="leader" weight="lighter" align="center" mb="xl">
                Checkout our FAQs for more information about how Green Financial works
            </P>
            <Grid
                columns={{ default: '1fr', laptop: '1fr 1fr' }}
                columnGap="xl"
                rowGap="sm"
                mb="lg"
            >
                <Cell>
                    <Type font="p" color="primary">
                        <Link to="/faq#q1">How long does it take to get approved?</Link>
                    </Type>
                </Cell>

                <Cell>
                    <Type font="p" color="primary">
                        <Link to="/faq#q2">What is Same Day Funding?</Link>
                    </Type>
                </Cell>

                <Cell>
                    <Type font="p" color="primary">
                        <Link to="/faq#q3">Is this program a loan product?</Link>
                    </Type>
                </Cell>

                <Cell>
                    <Type font="p" color="primary">
                        <Link to="/faq#q4">How does payback work?</Link>
                    </Type>
                </Cell>

                <Cell>
                    <Type font="p" color="primary">
                        <Link to="/faq#q5">How much will it cost?</Link>
                    </Type>
                </Cell>

                <Cell>
                    <Type font="p" color="primary">
                        <Link to="/faq#q6">Will filling out a pre-qualification request affect my credit score?</Link>
                    </Type>
                </Cell>
            </Grid>
            <Type color="primary" weight="lighter" align="center">
                <Link to="/faq">Read More Faqs &rarr;</Link>
            </Type>
        </Container>
    </Box>
);

FaqPanel.defaultProps = {};

export default FaqPanel;
