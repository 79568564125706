import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Check } from 'react-feather';
import { Steps, Box, ThemeContext } from '@saladbob/sassafras';
import { useMediaQuery } from 'react-responsive';

const styles = createUseStyles({
    Header: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        zIndex: 2,
        background: ({ bgColor, theme }) => theme.colors[bgColor] || bgColor,
        padding: '1rem',
    },
    Body: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: ({ theme }) => `${theme.breakpoints.desktop.minWidth}px`,
    },
    Logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: '24px',
        width: '100%',
        height: '100%',
        maxWidth: '42px',
        '& img': {
            display: 'block',
            width: '100%',
        },
    },
    '@media (max-width: 869px)': {
        Body: {
            flexWrap: 'wrap',
        },
    },
});

const HeaderSteps = ({
    logo, step, ...props
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, ...props });

    const mobile = !useMediaQuery(theme.breakpoints.laptop);

    return (
        <div className={classes.Header}>
            <div className={classes.Body}>
                {!mobile && (
                    <div className={classes.Logo}>
                        <img src={logo} alt="logo" />
                    </div>
                )}
                <Box maxWidth="360px" margin="auto">
                    <Steps
                        steps={['Request', 'Info', 'Financials', 'Contact']}
                        step={step}
                        completedIcon={<Check />}
                        activeColor="primary"
                        navigation={false}
                    />
                </Box>
            </div>
        </div>
    );
};

HeaderSteps.propTypes = {
    logo: PropTypes.string,
    step: PropTypes.number,
    bgColor: PropTypes.string,
};

HeaderSteps.defaultProps = {};

export default HeaderSteps;
