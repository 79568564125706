import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    H1,
    H2,
    H3,
    Box,
    Container,
    Grid,
    ThemeContext,
} from '@saladbob/sassafras';

import ImageLines2 from '../components/ImageLines2';
import ImageCircle from '../components/ImageCircle';
import DottedBorder from '../components/DottedBorder';

const About = ({ imgPath }) => {
    const { breakpoints } = useContext(ThemeContext);

    return (
        <>
            <Box
                bgColor="primary"
                bgImage={`${imgPath}about-bg-overlay.svg`}
                bgPos="120% top"
                bgSize="auto 100%"
                align="center"
                height="50vh"
                padding={['xl', 'md']}
            >
                <Container size="lg" margin="auto">
                    <H1 color="neutral50" font={{ default: 'h1', laptop: 'jumbo' }} mb="lg">
                        We believe in small to medium-sized businesses and the owners that make them grow and succeed.
                    </H1>
                    <H2 color="white" font="h3" weight="lighter">
                        Using the latest technology, data science, and customer support we aim
                        to provide business owners with the help they need to achieve success!
                    </H2>
                </Container>
            </Box>
            <Box
                bgColor="neutral50"
                margin="auto"
            >
                <Grid columns={{ default: '1fr', laptop: '1fr 1fr' }} rowGap="lg" columnGap="xxl" align="center">
                    <Box height="100%" display="flex">
                        <ImageLines2 image={`${imgPath}about-img-1.jpg`} />
                    </Box>
                    <Box
                        maxWidth={`${breakpoints.desktop.minWidth / 2}px`}
                        padding={{ default: 'lg', laptop: ['xl', 0] }}
                        me="auto"
                    >
                        <Box display="flex" align="center" mb="xl">
                            <DottedBorder color="secondary" me="md" />
                            <H1
                                font={{ default: 'h1', laptop: 'large' }}
                                color="primary"
                                mb="0"
                            >
                                Fast And Hassle-free Funding Nationwide
                            </H1>
                        </Box>
                        <H3
                            font={{ default: 'h2', laptop: 'h4' }}
                            weight="300"
                            mb="xl"
                        >
                            Backed by decades of experience, Green Financial is a leading financial services company
                            focused on providing unsecured business advances, and other alternative forms of funding,
                            known as Merchant Cash Advance, to business owners nationwide. Our team of seasoned
                            financial consultants have helped many small to medium-sized businesses secure much needed
                            capital through our programs which offer an alternative to loans, who otherwise may not
                            qualify through traditional channels.
                        </H3>
                    </Box>
                </Grid>
            </Box>
            <Box padding="xxl">
                <Container size="lg" margin="auto">
                    <Grid columns={{ phone: '1fr 1fr', laptop: '1fr 1fr 1fr 1fr' }} gridGap="lg" mb="xxl">
                        <ImageCircle image={`${imgPath}about-img-2.jpg`} />
                        <ImageCircle image={`${imgPath}about-img-3.jpg`} />
                        <ImageCircle image={`${imgPath}about-img-4.jpg`} />
                        <ImageCircle image={`${imgPath}about-img-5.jpg`} />
                    </Grid>
                    <H1 color="primary" font={{ default: 'h1', desktop: 'jumbo' }} weight="lighter" mb="xl">
                        Our culture is built on a highly energetic team of people who are dedicated to helping
                        small businesses.
                    </H1>
                    <H3 weight="normal">
                        We bring a customized approach, a fresh insight and an uncommon passion to the world of
                        business funding. We serve America&apos;s small businesses and help them obtain the working
                        capital that they need with a fast and hassle-free funding process.
                    </H3>
                </Container>
            </Box>
        </>
    );
};
About.propTypes = {
    imgPath: PropTypes.string,
};

About.defaultProps = {};

export default About;
