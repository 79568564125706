import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import {
    ThemeContext,
} from '@saladbob/sassafras';

const sizingMap = {
    xs: 'calc(1.25rem + 2px)',
    sm: 'calc(2.5rem + 2px)',
    md: 'calc(3rem + 2px)',
    lg: 'calc(3.5rem + 2px)',
    xl: 'calc(4rem + 2px)',
    xxl: 'calc(5rem + 2px)',
};

const styles = createUseStyles({
    Button: {
        height: ({ size }) => sizingMap[size],
        width: ({ size }) => sizingMap[size],
        background: ({ theme, btnColor }) => theme.colors[btnColor] || btnColor,
        position: 'relative',
        transform: 'rotate(90deg)',
        transition: 'all .35s ease',
        borderRadius: ({ size }) => sizingMap[size],
        border: 'none',
        '& span': {
            background: '#fff',
            height: '10%',
            width: '10%',
            borderRadius: '1rem',
            position: 'absolute',
            top: '25%',
            left: '45%',
            transition: 'all .35s ease',
            cursor: 'pointer',
        },
        '& span:nth-child(1)': {

        },
        '& span:nth-child(2)': {
            top: '45%',
        },
        '& span:nth-child(3)': {
            top: '65%',
        },
    },
    Active: {
        transform: 'rotate(0)',
        zIndex: '10002',
        '& span': {
            height: '6%',
            width: '60%',
            left: '20%',
        },
        '& span:nth-of-type(1)': {
            top: '50%',
            transform: 'rotate(45deg)',
        },
        '& span:nth-of-type(2)': {
            opacity: 0,
        },
        '& span:nth-of-type(3)': {
            top: '50%',
            transform: 'rotate(-45deg)',
        },
    },
    Nav: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '10000',
        left: 0,
        top: 0,
        background: ({ theme, navBgColor }) => theme.colors[navBgColor] || navBgColor,
    },
});

const MobileNav = ({
    children, btnColor, navBgColor, size,
}) => {
    const [active, setActive] = useState(false);
    const theme = useContext(ThemeContext);

    const classes = styles({
        theme,
        btnColor,
        navBgColor,
        size,
    });

    function toggle() {
        setActive(!active);
    }

    return (
        <>
            <button className={`${classes.Button} ${active ? classes.Active : ''}`} type="button" onClick={toggle}>
                <span />
                <span />
                <span />
            </button>
            {active && (
                <div className={classes.Nav} onClick={toggle} role="presentation">
                    <div>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

MobileNav.propTypes = {
    children: PropTypes.node,
    btnColor: PropTypes.string,
    navBgColor: PropTypes.string,
    size: PropTypes.string,
};

MobileNav.defaultProps = {
    size: 'md',
    navBgColor: 'primary800',
    btnColor: 'primaryA700',
};

export default MobileNav;
