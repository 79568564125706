export default {
    colors: {
        primary: {
            process: '#00A661',
        },
        primaryLight: '#1FCC7A',
        secondary: {
            process: '#E5C955',
        },
        neutral50: '#E6F4EC',
        neutral100: '#DAE5DF',
        neutral200: '#C2CCC6',
        neutral300: '#B8BFBA',
        neutral400: '#C2CCC6',
        neutral: '#9DA5A0',
        neutral600: '#8B918D',
        neutral700: '#7D827E',
        neutral800: '#707572',
        neutral900: '#4E514F',
    },
    fontFamilies: {
        heading: ['din-2014', 'sans-serif'],
        body: ['din-2014', 'sans-serif'],
    },
    typography: {
        p: {
            fontFamily: 'body',
            fontSize: '1.25rem',
            lineHeight: '2rem',
        },
        large: {
            fontFamily: 'heading',
            fontSize: '2.75rem',
            lineHeight: '3rem',
        },
        company: {
            fontFamily: 'heading',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            textTransform: 'uppercase',
        },
    },
};
