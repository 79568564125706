import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    useNavigate,
} from 'react-router-dom';

import {
    Btn,
    H1,
    H3,
    P,
    Type,
    Box,
    Grid,
    Cell,
    ThemeContext,
} from '@saladbob/sassafras';

import ImageLines from '../components/ImageLines';
import DottedBorder from '../components/DottedBorder';
import Feature from '../components/Feature';
import FaqPanel from '../components/FaqPanel';

const Home = ({
    imgPath,
}) => {
    const navigate = useNavigate();

    const { breakpoints, spacing } = useContext(ThemeContext);

    const containerWidth = `${breakpoints.desktop.minWidth}px`;

    const columnMargin = `calc(50vw - ${breakpoints.desktop.minWidth / 2}px - ${spacing.xl})`;

    function getStarted() {
        navigate('/apply?step=0');
    }

    return (
        <>
            <Box
                bgColor="neutral50"
                padding="xl"
                margin="auto"
            >
                <Grid columns={{ default: '1fr', laptop: '3fr 2fr' }} rowGap="lg" columnGap="lg" align="center">
                    <Box
                        maxWidth={`${breakpoints.desktop.minWidth * 0.6}px`}
                        ms={{ default: 'auto', desktop: columnMargin }}
                    >
                        <Box display="flex" align="center" mb={{ default: 'sm', laptop: 'lg' }}>
                            <DottedBorder color="secondary" me="md" />
                            <H1 font={{ default: 'h1', laptop: 'jumbo' }} color="primary" mb="0">
                                Funding That’s Tailored For Your Business
                            </H1>
                        </Box>
                        <H1
                            font={{ default: 'h3', laptop: 'h1' }}
                            weight="300"
                            mb="xl"
                        >
                            Don’t let cash flow issues slow you down. Our Business funding is fast and simple!
                        </H1>
                        <Btn size="xxl" font="h3" weight="bold" mb="md" depth="md" onClick={getStarted}>
                            GET QUOTE
                        </Btn>
                        <P font="h4" weight="bold">
                            or call 1-888-520-5542
                        </P>
                    </Box>
                    <Box maxWidth={`${breakpoints.desktop.minWidth * 0.75}px`}>
                        <ImageLines image={`${imgPath}hero-header-bg.jpg`} />
                    </Box>
                </Grid>
            </Box>

            <Box padding={[0, 'lg']} style={{ overflow: 'hidden' }}>
                <Box
                    maxWidth={containerWidth}
                    margin={['xxl', 'auto']}
                >
                    <Grid
                        columns={{ default: '1fr', laptop: '1fr 1fr 1fr' }}
                        columnGap="lg"
                        rowGap="lg"
                        maxWidth={containerWidth}
                        margin={['xxl', 'auto']}
                    >
                        <Cell>
                            <Box margin="auto" mb="lg">
                                <img src={`${imgPath}step-1-ico.svg`} alt="Online Form Icon" />
                                <Box
                                    bgColor="primary"
                                    height="1.8%"
                                    maxWidth={`calc(24% + ${spacing.lg})`}
                                    style={{
                                        position: 'absolute',
                                        left: '99%',
                                        top: '49%',
                                    }}
                                />
                            </Box>
                            <Feature
                                title="Complete online form"
                                disc="Our application is simple, and you can apply online or over the phone."
                                align="center"
                            />
                        </Cell>
                        <Cell>
                            <Box margin="auto" mb="lg">
                                <img src={`${imgPath}step-2-ico.svg`} alt="Financials Icon" />
                                <Box
                                    bgColor="primary"
                                    height="1.8%"
                                    maxWidth={`calc(24% + ${spacing.lg})`}
                                    style={{
                                        position: 'absolute',
                                        left: '99%',
                                        top: '49%',
                                    }}
                                />
                            </Box>
                            <Feature
                                title="Submit financials & get a quote"
                                disc="Your dedicated US based advisor will review your funding options with you."
                                align="center"
                            />
                        </Cell>
                        <Cell>
                            <Box margin="auto" mb="lg">
                                <img src={`${imgPath}step-3-ico.svg`} alt="Clock Icon" />
                            </Box>
                            <Feature
                                title="Get your funds!"
                                disc="Once approved, you can receive the funds as soon as same business day!"
                                align="center"
                            />
                        </Cell>
                    </Grid>
                </Box>
            </Box>
            <Box
                bgColor="neutral50"
                padding="xl"
                margin="auto"
            >
                <Grid columns={{ default: '1fr', laptop: '2fr 3fr' }} rowGap="lg" columnGap="lg" align="center">
                    <Box
                        maxWidth={`${breakpoints.desktop.minWidth * 0.75}px`}
                    >
                        <ImageLines image="public/images/customer-service-bg.jpg" />
                    </Box>
                    <Box
                        maxWidth={`${breakpoints.desktop.minWidth * 0.6}px`}
                        ms="auto"
                        me={{ default: 'auto', desktop: columnMargin }}
                    >
                        <Box display="flex" align="center" mb="xl">
                            <DottedBorder color="secondary" me="md" />
                            <H1
                                font={{ default: 'h1', laptop: 'large' }}
                                color="primary"
                                mb="0"
                            >
                                We Are Here For Whatever Your Business Needs
                            </H1>
                        </Box>
                        <H3
                            font={{ default: 'h4', laptop: 'h2' }}
                            weight="300"
                            mb="xl"
                        >
                            You have big dreams and goals and we are here to ensure the we provide the funding
                            solution needed to achieve them!
                        </H3>
                        <H3
                            font={{ default: 'h4', laptop: 'h2' }}
                            weight="300"
                            mb="xl"
                        >
                            Being Online doesn&lsquo;t have to mean being impersonal. With Green Financial you get real
                            people to talk to. A dedicated, U.S. based advisor is available to help you with every
                            step of the way.
                        </H3>
                        <Type tag="p" font="h1" weight="bold">
                            Call:&nbsp;
                            <Type color="primary" font="h1">
                                1-888-520-5542
                            </Type>
                        </Type>
                        <Type tag="p" font="h4" weight="bold">
                            Monday – Friday 8am – 5pm PT
                        </Type>
                    </Box>
                </Grid>
            </Box>
            <FaqPanel />
        </>
    );
};

Home.propTypes = {
    imgPath: PropTypes.string,
};

export default Home;
