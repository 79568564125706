import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
    ArrowRightCircle,
    Loader,
} from 'react-feather';

import {
    Type,
    H2,
    ValidationForm,
    FieldSelect,
    HiddenInput,
    Btn,
    Box,
} from '@saladbob/sassafras';

const FormStep1 = ({
    requestedLoans,
    useOfFunds,
    onSubmit,
    userData,
    loading,
    errMsg,
    submitBtnColor,
    submitBtnHoverColor,
    submitBtnTxtColor,
}) => {
    const formRef = useRef();

    function validate() {
        formRef.current.validate();
    }

    return (
        <Box color="white">
            <H2 font="h1" mb="md" color="white" align="center" weight="300">
                LET&apos;S GET TO KNOW YOUR BUSINESS
                <br />
                & HOW WE CAN HELP YOU
            </H2>
            {errMsg && (
                <H2 font="h3" mb="md" color="white">
                    {errMsg}
                </H2>
            )}
            <ValidationForm ref={formRef} onSubmit={onSubmit}>
                <FieldSelect
                    name="loan_purpose"
                    label={(<Type font="leader" color="white">I need capital for:</Type>)}
                    placeholder="--Select--"
                    value={userData.loan_purpose}
                    margin={[0, 0, 'md', 0]}
                    borderColor={false}
                    options={useOfFunds}
                    rules={{ required: true }}
                />

                <FieldSelect
                    name="amount"
                    label={(<Type font="leader" color="white">How much do you need:</Type>)}
                    placeholder="--Select--"
                    value={userData.amount}
                    margin={[0, 0, 'xl', 0]}
                    borderColor={false}
                    options={requestedLoans}
                    rules={{ required: true }}
                />
                <HiddenInput name="s1" value={userData.s1} />
                <HiddenInput name="s2" value={userData.s2} />
                <HiddenInput name="s3" value={userData.s3} />
                <HiddenInput name="affiliate" value={userData.affiliate} />
                <HiddenInput name="affiliate_id" value={userData.affiliate_id} />
            </ValidationForm>
            <Btn
                font="h3"
                type="button"
                color={submitBtnColor}
                hoverColor={submitBtnHoverColor}
                txtColor={submitBtnTxtColor}
                size="lg"
                iconRight={(<ArrowRightCircle size="32" />)}
                onClick={validate}
                loading={loading}
                iconLoading={(<Loader size="32" />)}
            >
                CONTINUE
            </Btn>
        </Box>
    );
};

FormStep1.propTypes = {
    onSubmit: PropTypes.func,
    userData: PropTypes.object,
    loading: PropTypes.bool,
    errMsg: PropTypes.string,
    submitBtnColor: PropTypes.string,
    submitBtnHoverColor: PropTypes.string,
    submitBtnTxtColor: PropTypes.string,
    requestedLoans: PropTypes.object,
    useOfFunds: PropTypes.object,
};

FormStep1.defaultProps = {
    userData: {},
};

export default FormStep1;
