/* eslint max-len: "off" */
import React from 'react';
import {
    H1,
    H3,
    P,
    List,
    Li,
    A,
    Box,
    Container,
} from '@saladbob/sassafras';

const Privacy = () => (
    <Box bgColor="neutral50" padding="xxl">
        <Container size="md" margin="auto" bgColor="white" padding="lg">
            <H1>Privacy Policy</H1>
            <P>Last Revised:  January 1, 2022</P>

            <P>
                MyGreenFinancial.com (&ldquo;Company&rdquo; &ldquo;we&rdquo; or &ldquo;our&rdquo;) is committed to advising you of the right to your
                privacy and strives to provide a safe and secure user experience.  This Privacy Policy explains how we
                collect, store and use personal information provided by you on our Website.  By accessing and using our
                Website, you explicitly accept, without limitation or qualification, the collection, use and transfer
                of the personal information provided by you in the manner described in this Privacy Policy.  Please
                read this Policy carefully, as it affects your rights and our liabilities under the law.  If you
                disagree with the way we collect and process personal information, please do not use our Website.
            </P>
            <H3>Information We Collect.</H3>
            <List listType="nested" spacing="lg">
                <Li>
                    We collect general types of information about you when you visit and use our Website; examples
                    include personal information, demographic information, behavioral information, and indirect
                    information. Sometimes we collect combinations of these types of information:
                    <List>
                        <Li>
                            Personal Information. Is information that can be used to identify you or any other
                            individual to whom the information may relate.  This is information which you are prompted
                            to provide to us.  Such information may include your name, address, telephone number(s),
                            mobile numbers and email address, credit card number(s), bank account(s), employment status
                            or self-employment or other unique information about you which you provide to us during the
                            registration process, or through the course of communicating with us about the products
                            and services provided on our Website.  We may collect this same information about all other
                            persons when you provide it to us.
                        </Li>
                        <Li>
                            Demographic Information. Is information that may or may not be unique to you in the
                            sense that it refers to selected population characteristics.  Such information may include,
                            but is not limited to, zip code, mobile phone carrier, age, gender, salary range,
                            education, marital status, occupation, military service, industry of employment, personal
                            and online interests. When we can identify you personally through the use of this
                            information, it becomes Personal Information.
                        </Li>
                        <Li>
                            Behavioral Information.  Is information which is automatically collected when you use
                            our Website.  This information includes the areas of our Website that you visit, what
                            services you access or ordered, information about your computer hardware and software,
                            including your IP address, geographic location, browser preference, operating system type,
                            domain names, times that you access the internet, and other websites you have visited.
                            When we can identify you personally through the use of this information, it becomes
                            Personal Information.
                        </Li>
                        <Li>
                            Third-Party Information.  Is information about you that we acquire from a third-party
                            which may include personal, demographic, behavioral and indirect information.  This
                            collection may also include, but not limited to, first party cookies, third-party cookies,
                            anonymous cookies, persistent identifiers, email opt-in records and search engine keywords.
                            We may also receive information third-parties derive from government entities which
                            publicly post your informatio other tracking devices used by third-parties including
                            advertisers and networks, internet service providers, data analytics providers or social
                            networks and platforms.  We have no responsibility or liability for the policies and
                            practices of these parties.  When we can identify you personally through the use of this
                            information, it becomes Personal Information.
                        </Li>
                        <Li>
                            Web Technology Information.  Is information we automatically collect from you when you
                            visit our Website.  This includes internet protocol (&ldquo;IP&rdquo;) addresses, browser type, internet
                            service provider (&ldquo;ISP&rdquo;), referring/exit pages, operating system, date/time stamp, and/or
                            clickstream data.  This information is collected from the use of cookies, Web beacons or
                            JavaScript.
                        </Li>
                        <Li>
                            No Information Collected from Children.  We will never knowingly collect any Personal
                            Information from children under the age of 16.  If we obtain actual knowledge that we have
                            collected Personal Information about a child under the age of 16, that information will be
                            immediately deleted from its database.  Because it does not collect such information, we
                            have no such information to use or to disclose to third-parties.
                        </Li>
                        <Li>
                            No Sensitive Health Information is Collected.  We do not collect information about
                            past, present or potential future medical conditions or treatments.  Nor do we collect any
                            biometric information.
                        </Li>
                        <Li>
                            Credit Card Information and Bank Account Information.  We may, in certain instances,
                            collect credit card number(s), bank account information and related information, when you
                            place an order on our Website.  When the credit card or bank account information is
                            submitted to us, such information is encrypted and is protected with SSL encryption
                            software.  We will use the credit card information or bank account information for purposes
                            of processing and completing the transaction you requested through a third-party processor.
                            As such, information will be disclosed to third-parties as necessary to complete the
                            requested purchase transaction.
                        </Li>
                    </List>
                </Li>
                <Li>
                    Collection of Information Through Cookies, Web Beacons, and JavaScript.
                    <List>
                        <Li>
                            Generally, we as well as third-party vendors, networks, ISP(s) and supporting
                            advertisers use technologies such as cookies, web beacons, and java scripts.  These
                            technologies collect internet protocol (&ldquo;IP&rdquo;) addresses, browser type, internet service
                            provider (&ldquo;ISP&rdquo;), referring/exit pages, operating system, date/time stamp, and/or
                            clickstream data.  This information is used to analyze trends, administer our Website,
                            track users’ movements through our Website and gather demographic information about our
                            user base as a whole.  We may receive reports based on these technologies on an individual
                            or aggregated basis.  California residents have the right to know if we respond to do not
                            track signals or cookies.  We do not respond to such signals or cookies.
                        </Li>
                        <Li>
                            Cookies.  &ldquo;Cookies&rdquo; are a feature in your browser software.  If enabled, cookies
                            store small amounts of data on your computer about actions you take on the pages of our
                            Website. Cookies assist us in tracking which of our features you visit most often, and what
                            content you viewed on past visits.  When you visit this website again, cookies allow us to
                            remember your settings and may be used for authentication.  We may use cookies to keep
                            track of the number of return visits, accumulate and aggregate statistical information
                            generally pertaining to our Website, and deliver specific content to you based on your past
                            viewing history. You can disable cookies, although our Website may not function properly
                            for you.  Your browser preferences can be modified to accept or reject all cookies or
                            request a notification when a cookie is set.  You may read more about cookies at&nbsp;
                            <A href="http://cookiecentral.com/faq/">http://cookiecentral.com/faq/</A>
                        </Li>
                        <Li>
                            Third-Party Cookies.  We allow third-party vendors and advertisers to set their own
                            cookies on and through our Website.  We have no control over the practices of those
                            third-parties and are not responsible for their technology or tracking.  We encourage you
                            to review the policies of such persons or entities on their websites.  We use various ad
                            services provided by Google that connects the activity on our Website with their
                            advertising network and they may use cookies that collects information regarding certain
                            patterns in your browsing history.  To opt-out of this tracking, please see&nbsp;
                            <A href="https://support.google.com/ads/answer/2662922?hl=en.">
                                support.google.com
                            </A>
                            .&nbsp;
                            We also participate in all of Google’s
                            Analytics Advertising.  This includes: (i) Remarketing with Google Analytics; (ii) Google
                            Display Network Impression Reporting; (iii) Google controlled Campaign Manager integration;
                            and (iv) Google Analytics Demographics and Interest Reporting.  Google uses cookies to
                            track activity performed by Google Analytics and its AdWords or DoubleClick cookie.
                            To opt-out please see&nbsp;
                            <A href="https://tools.google.com/dlpage/gaoptout/">
                                tools.google.com
                            </A>
                            .
                            We also use Google
                            Analytics which is an analysis service.  Google utilizes the data collected through its
                            cookies to track and examine the use of this site, to prepare reports on its activities,
                            and to share them with other Google services.  You may opt-out of the Google Analytics
                            service using Google’s Browser Add-on&nbsp;
                            <A href="https://tools.google.com/dlpage/gaoptout/">
                                here
                            </A>
                            .
                        </Li>
                        <Li>
                            Social Media Cookies. Our Website includes third-party social media features.
                            These features may collect your IP address, which page you are visiting on our Website,
                            and may set a cookie to enable the feature to function properly.  Social media features
                            are either hosted by a third-party or hosted directly on our Website.  Your interactions
                            with these features are governed by the Privacy Policy of the company providing it.
                        </Li>
                        <Li>
                            Web Beacons.  We use electronic images known as Web Beacons (sometimes called
                            single-pixel gifs, clear gifs or action tags) which allow us to collect information about
                            your visit to our Website, measure and improve the effectiveness of advertisements and
                            track delivery of advertising.  Web Beacons collect only a limited set of information
                            including a cookie number, time and date of page view, as well as a description of the
                            page on which the Web Beacon resides.  We may also use Web beacons in email messages sent
                            to you.  This allows us to determine if you opened or acted upon the email message.
                            Because Web beacons are the same as any other content request, you cannot opt-out or
                            refuse them.  However, they can be rendered ineffective by either opting-out of cookies or
                            changing the cookie setup in your browser.
                        </Li>
                        <Li>
                            JavaScript.  We may also use JavaScript. JavaScript is a computer language that
                            enhances the functionality of websites, particularly with respect to pictures.  We use it to
                            analyze and improve our Website’s functions.  You may deactivate JavaScript through your
                            browser settings or activate it the same way.  If you disable JavaScript, you will not be
                            able to use some of the functions of our Website.
                        </Li>
                    </List>
                </Li>
                <Li>
                    How We Use Information Collected.
                    <List>
                        <Li>
                            Providing Services and Products.  We use the information we gather on our Website to
                            provide you with the services and or products you have requested.  This may include passing
                            your information on to a third-party to provide such services or products.  Although our
                            contractual arrangement limits how this party can use your information, we do not control
                            the privacy practices of third-parties.  If you have any questions or wish to remove your
                            information from the third-party databases, you will need to contact that party directly.
                        </Li>
                        <Li>
                            Improving Our Website.  We use the information we gather to respond to any inquiries
                            you make, operate and improve the functionality of our Website, and deliver the products and
                            services advertised on our Website.  We use the same for testing, research, analysis and
                            product or marketing development.  Our services include the display of personalized
                            products, content, and advertising, relating to your experience and interests. This includes
                            maintaining and improving the safety, security and integrity of our Website, databases and
                            technology assets.
                        </Li>
                        <Li>
                            Promotions and Communications with You.  We also use the information we gather to
                            promote our and third-party services and products to you that might be of interest to you.
                            We also use the information we have gathered to respond to your inquiries, concerns or
                            requests.  If your request is made through a third-party, we may use the information to
                            respond to their requests.
                        </Li>
                    </List>
                </Li>
                <Li>
                    Disclosure of Information to Others.
                    <List>
                        <Li>
                            We will share your Personal Information with third-parties and service providers as
                            described in this Privacy Policy.
                        </Li>
                        <Li>
                            Product and Service Delivery.  We share all categories of information set out in
                            this policy with third-parties who help us in the delivery of the products and services you
                            have requested.
                        </Li>
                        <Li>
                            Website Functionality.  We share all categories of information set out in this
                            policy with third-parties we employ to perform technical functions on our behalf. Examples
                            include third-parties who host our Website, analyze our data, provide marketing assistance,
                            process credit card payments, and provide customer service as set forth above.
                        </Li>
                        <Li>
                            Third-Party Products and Services.  We share all categories of information set out
                            in this policy with third parties including affiliated entities, service providers, and data
                            aggregators with your information with third parties who will provide you with their
                            opportunities, products or services.  Again, this includes your personal and non-personal
                            information, and includes your interests and preferences, so they may determine whether you
                            might be interested in their products or services.
                        </Li>
                        <Li>
                            Anonymized information.  We share aggregated anonymous information about you,
                            combined with other persons using our Website with third-parties, so that they can
                            understand the kinds of visitors that come to our Website, and how those visitors use our
                            Website and promotion of our marketing.  This includes demographic information and
                            behavioral information.
                        </Li>
                        <Li>
                            Legal Process.  We disclose your information if legally required to do so, or at our
                            discretion, pursuant to a request from a governmental entity, or if we believe in good faith
                            that such action is necessary to: (a) conform to legal requirements or comply with legal
                            process; (b) protect our rights or property, or our affiliated companies; (c) prevent a
                            crime or protect national security; or (d) protect the personal safety of users or the
                            public.
                        </Li>
                        <Li>
                            Acquisition or Merger.  We may disclose and transfer your information to a
                            third-party who acquires any or all of our business, whether such acquisition is by way of
                            merger, consolidation or purchase of all or a substantial portion of our assets.  In the
                            event we become the subject of an insolvency proceeding, whether voluntary or involuntary,
                            we or our liquidator, administrator, receiver or administrative receiver may sell, license
                            or otherwise dispose of, such information in a transaction approved by the court.
                        </Li>
                    </List>
                </Li>
                <Li>
                    Third-Party Collection and Use of Information.
                    <List>
                        <Li>
                            Third-parties collect and use information about you on or through our Website in the
                            following way:
                        </Li>
                        <Li>
                            Advertisers. Advertising agencies, advertising networks, and other companies who
                            place ads on our Website, may use their own cookies, Web beacons, and other technology, to
                            collect information about you.  We do not control the use of such technology and have no
                            responsibility for the use of such technology to gather information about you.
                        </Li>
                        <Li>
                            Hyperlinks. Our Website and email messages sometimes contain hypertext links to
                            websites owned by third-parties.  We are not responsible for the privacy practices or the
                            content of such other websites.  These links are provided for your convenience and
                            reference only.  We do not operate or control any information, software, products or
                            services, available on these third-party websites.  The inclusion of a link on our Website
                            does not imply any endorsement of the services, products or website, or its sponsoring
                            organization.
                        </Li>
                        <Li>
                            Analytics. As described above, we use third-parties to monitor, analyze and report
                            on the traffic to, from and within our Website and email messages.
                        </Li>
                    </List>
                </Li>
                <Li>
                    Security.
                    <List>
                        <Li>
                            We endeavor to safeguard and protect your information.  When you submit information on
                            our Website, such information is protected both online and offline.  We have security
                            measures in place to protect against the loss, misuse, and alteration of personal
                            information under our control. The servers in which we store your information are kept in a
                            secure physical environment. The servers have industry standard firewalls. Access to such
                            servers is password protected and access by our employees is limited.  Currently, we use
                            Secure Socket Layer software (&ldquo;SSL&rdquo;) to protect data and to secure any transactions. SSL
                            encrypts information including credit card number(s), and names and addresses, as they are
                            transmitted over the Internet.  Please be advised that, although we take commercially
                            reasonable technological precautions to protect your data, no data transmission over the
                            Internet can be guaranteed to be 100% secure; therefore, we cannot and do not warrant that
                            your information will be absolutely secure.  Any transmission of data at or through our
                            Website is at your own risk. However, access to your information is strictly limited and
                            not accessible to the public.
                        </Li>
                    </List>
                </Li>
                <Li>
                    Changes to Privacy Policy.
                    <List>
                        <Li>
                            We reserve the right to make material changes to the substance of this Privacy
                            Policy.  We will post those changes through a prominent notice on the website, so that you
                            will always know what information we gather, how we might use that information, and to whom
                            we will disclose it.
                        </Li>
                    </List>
                </Li>
                <Li>
                    California Residents Rights.
                    <List>
                        <Li>
                            The California Consumer Protection Act (&ldquo;CCPA&rdquo;) provides California residents with
                            specific rights regarding their personal information.  Please click here for a description
                            of those rights and how to exercise those rights, including the do not sell my personal
                            information right afforded you.
                        </Li>
                        <Li>
                            In addition, California’s Shine the Light Law provides California residents with the
                            right to receive, once a year, information about third-parties by category with whom we
                            have shared information about you or your family for their marketing purposes during the
                            previous calendar year, and a description of the categories of personal information shared.
                            California residents also have the right to know if we respond to do not track signals or
                            cookies.  We do not respond to such signals or cookies.  As stated in this Policy, you have
                            agreed to allow us to share information with third parties for their direct marketing
                            purposes until you remove your information; and thus, you have agreed to this disclosure.
                            California customers may request further information about our compliance with this law by
                            sending us an email at&nbsp;
                            <A href="mailto:admin@mygreenfinancial.com">admin@mygreenfinancial.com</A>
                            .&nbsp;
                            Please note that we are only required to respond to one request per customer each year,
                            and we are not required to respond to requests made by means other than through this email
                            address.
                        </Li>
                    </List>
                </Li>
            </List>
            <H3>
                CONTACT US
            </H3>
            <P>
                If you have any questions regarding this privacy policy you may contact us using the following
                information below:
            </P>

            <P>
                Email:
                <A href="mailto:admin@mygreenfinancial.com">admin@mygreenfinancial.com</A>
            </P>

            <P>
                Send a letter:
                <br />
                MyGreenFinancial.com
                <br />
                8 The Green, Suite 13340
                <br />
                Dover DE 19901
            </P>

        </Container>
    </Box>
);

Privacy.defaultProps = {};

export default Privacy;
