import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Link,
    useNavigate,
} from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import {
    Btn, Box, ThemeContext,
} from '@saladbob/sassafras';
import { useMediaQuery } from 'react-responsive';

import {
    Phone,
} from 'react-feather';

import MobileNav from './MobileNav';

const styles = createUseStyles({
    Header: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        background: ({ bgColor, theme }) => theme.colors[bgColor] || bgColor,
        padding: '1.25rem',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 90000,
    },
    Body: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        width: '100%',
        maxWidth: ({ theme }) => `${theme.breakpoints.desktop.minWidth}px`,
    },
    Logo: {
        width: '100%',
        maxWidth: '200px',
        marginRight: 'auto',
        '& img': {
            display: 'block',
            width: '100%',
        },
    },
    '@media (max-width: 869px)': {
        Body: {
            flexWrap: 'wrap',
        },
    },
});

const Header = ({
    logo, nav, navMobile, ...props
}) => {
    const theme = useContext(ThemeContext);

    const navigate = useNavigate();

    const classes = styles({ theme, ...props });

    const mobile = !useMediaQuery(theme.breakpoints.laptop);

    function getStarted() {
        navigate('/apply?step=0');
    }

    return (
        <Box height="82px">
            <div className={classes.Header}>
                <div className={classes.Body}>
                    <div className={classes.Logo}>
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    {!mobile && nav && (
                        <>
                            {nav}
                            <Btn
                                href="tel:18885205542"
                                tag="a"
                                size="sm"
                                weight="bold"
                                iconLeft={(<Phone />)}
                                txtColor="black"
                                color="white"
                                margin={[0, 'auto']}
                            >
                                1-888-520-5542
                            </Btn>
                            <Btn size="sm" color="primary" weight="bold" onClick={getStarted}>
                                GET QUOTE
                            </Btn>
                        </>
                    )}
                    {mobile && navMobile && (
                        <MobileNav>
                            {navMobile}
                        </MobileNav>
                    )}
                </div>
            </div>
        </Box>
    );
};

Header.propTypes = {
    logo: PropTypes.string,
    bgColor: PropTypes.string,
    navBgColor: PropTypes.string,
    nav: PropTypes.node,
    navMobile: PropTypes.node,
};

Header.defaultProps = {};

export default Header;
