import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import {
    H1,
    H2,
    P,
    Box,
    Btn,
    Container,
    Grid,
    Cell,
    FieldText,
    FieldArea,
    HiddenInput,
    ValidationForm,
} from '@saladbob/sassafras';

const Contact = ({
    onSubmit,
    loading,
    completed,
    disc,
}) => {
    const formRef = useRef();
    const [token, setToken] = useState();
    const { executeRecaptcha } = useGoogleReCaptcha();

    function validate() {
        if (formRef.current) {
            formRef.current.validate();
        }
    }

    // Create an event handler so you can call the verification on button click event or form submit
    async function handleReCaptchaVerify() {
        if (!executeRecaptcha) {
            return;
        }

        const newToken = await executeRecaptcha();
        setToken(newToken);
    }

    useEffect(() => {
        if (token) {
            validate();
        }
    }, [token]);

    return (
        <Box bgColor="neutral50" padding={['xl', 'lg']}>
            {completed && (
                <>
                    <Box mb="xxl" />
                    <Box height="80px" bgColor="primary400" maxWidth="1100px" margin="auto" />
                    <Container size="md" bgColor="white" padding="xl" mt="-60px" margin="auto">
                        <H1 weight="bold" align="center" mb="xl" margin="auto">
                            Thank you for your interest  in learning more about Green Financial!
                        </H1>
                        <P align="center">
                            We received your message and will be reaching out to you shortly.
                        </P>
                        <P align="center">
                            If you need immediate assistance, please call one of our Funding Specialist at
                            <br />
                            1-888-520-5542. We are available Monday – Friday 8am – 5pm PT
                        </P>
                    </Container>
                </>
            )}
            {!completed && (
                <>
                    <H1 weight="bold" align="center" mb="xl" margin="auto">
                        CONTACT US
                    </H1>

                    <Box height="80px" bgColor="primary400" maxWidth="1100px" margin="auto" />
                    <Container size="lg" bgColor="white" padding="lg" mt="-60px" margin="auto">
                        <H2 font="h2" weight="300" mb="sm" align="center">
                            Looking to learn more before applying?
                            <br />
                            Get in touch for a no obligation consultation.
                        </H2>
                        <P font="leader" weight="lighter">
                            {disc}
                        </P>
                        <ValidationForm ref={formRef} onSubmit={onSubmit} onEnter={handleReCaptchaVerify}>
                            <Grid
                                columns={{ default: '1fr', laptop: '1fr 1fr' }}
                                columnGap="xl"
                                rowGap="xl"
                                mb="lg"
                            >
                                <Cell>
                                    <FieldText
                                        name="fname"
                                        label="First Name"
                                        font="p"
                                        rules={{ required: true }}
                                    />
                                </Cell>

                                <Cell>
                                    <FieldText
                                        name="lname"
                                        label="Last Name"
                                        font="p"
                                        rules={{ required: true }}
                                    />
                                </Cell>

                                <Cell>
                                    <FieldText
                                        name="email"
                                        label="Email"
                                        type="email"
                                        font="p"
                                        rules={{ required: true }}
                                    />
                                </Cell>

                                <Cell>
                                    <FieldText
                                        name="phone"
                                        label="Phone"
                                        type="tel"
                                        font="p"
                                        rules={{ phone: true }}
                                    />
                                </Cell>

                                <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
                                    <FieldArea
                                        id="message-field"
                                        name="message"
                                        label="Message"
                                        font="p"
                                        rules={{ required: true }}
                                    />
                                </Cell>
                            </Grid>
                            <HiddenInput name="token" value={token} />
                        </ValidationForm>

                        <Btn
                            color="primary"
                            weight="bold"
                            onClick={handleReCaptchaVerify}
                            loading={loading}
                            font="leader"
                        >
                            SUBMIT
                        </Btn>
                    </Container>
                </>
            )}
        </Box>
    );
};

Contact.propTypes = {
    disc: PropTypes.string,
    loading: PropTypes.bool,
    completed: PropTypes.bool,
    onSubmit: PropTypes.func,
};

Contact.defaultProps = {};

export default Contact;
