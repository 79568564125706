import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const location = useLocation();

    const scrollToHash = async () => {
        const { hash } = location;
        const removeHashCharacter = (str) => {
            const result = str.slice(1);
            return result;
        };

        if (hash) {
            const element = await new Promise((resolve) => setTimeout(() => {
                resolve(document.getElementById(removeHashCharacter(hash)));
            }), 100);

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    // block: "end",
                    inline: 'nearest',
                });
            }
        }
    };

    useEffect(() => {
        scrollToHash();
        window.scrollTo(0, 0);
    }, [location]);

    return (null);
}

export default ScrollToTop;
