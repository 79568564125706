import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import {
    ArrowLeftCircle,
    ArrowRightCircle,
    Loader,
} from 'react-feather';

import {
    Type,
    P,
    ValidationForm,
    FieldText,
    FieldSelect,
    FieldRadio,
    FieldCheckbox,
    HiddenInput,
    Btn,
    BtnText,
    Box,
    Grid,
    Cell,
} from '@saladbob/sassafras';

const radioOptions = {
    main: [
        { value: '1', label: '1', area: 'a' },
        { value: '2', label: '2', area: 'b' },
        { value: '3', label: 'More than 2', area: 'c' },
        // { value: '4', label: 'More than 3' },
    ],
    partnership: [
        { value: '2', label: '2', area: 'a' },
        { value: '3', label: 'More than 2', area: 'c' },
    ],
};

const ownerFields = (owner, partner, userData, states) => (
    <>
        <Cell column={{ default: 'span 1', laptop: 'span 4' }}>
            <Type font="leader" color="white">
                {`${partner ? 'Partner' : 'Owner'} ${owner}`}
                {' '}
                Contact Information
            </Type>
        </Cell>

        <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
            <FieldText
                name={`owner_${owner}_first_name`}
                label={(<Type font="p" color="white">First Name:</Type>)}
                value={userData[`owner_${owner}_first_name`]}
                borderColor={false}
                size="sm"
                rules={{ required: true }}
            />
        </Cell>
        <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
            <FieldText
                name={`owner_${owner}_last_name`}
                label={(<Type font="p" color="white">Last Name:</Type>)}
                value={userData[`owner_${owner}_last_name`]}
                borderColor={false}
                size="sm"
                rules={{ required: true }}
            />
        </Cell>
        <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
            <FieldText
                name={`owner_${owner}_home_address`}
                label={(<Type font="p" color="white">Address:</Type>)}
                value={userData[`owner_${owner}_home_address`]}
                borderColor={false}
                size="sm"
                rules={{ required: true }}
            />
        </Cell>
        <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
            <FieldText
                name={`owner_${owner}_city`}
                label={(<Type font="p" color="white">City:</Type>)}
                value={userData[`owner_${owner}_city`]}
                borderColor={false}
                size="sm"
                rules={{ required: true }}
            />
        </Cell>
        <Cell column={{ default: 'span 1', laptop: 'span 1' }}>
            <FieldSelect
                name={`owner_${owner}_state`}
                label={(<Type font="p" color="white">State:</Type>)}
                value={userData[`owner_${owner}_state`]}
                borderColor={false}
                size="sm"
                placeholder="--Select--"
                options={states}
                rules={{ required: true }}
            />
        </Cell>
        <Cell column={{ default: 'span 1', laptop: 'span 1' }}>
            <FieldText
                name={`owner_${owner}_zip`}
                label={(<Type font="p" color="white">Zip:</Type>)}
                value={userData[`owner_${owner}_zip`]}
                borderColor={false}
                size="sm"
                rules={{ required: true }}
            />
        </Cell>
        <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
            <FieldText
                name={`owner_${owner}_cell_phone`}
                label={(<Type font="p" color="white">Cell Phone:</Type>)}
                value={userData[`owner_${owner}_cell_phone`]}
                borderColor={false}
                type="tel"
                size="sm"
                rules={{ phone: true, required: true }}
            />
        </Cell>
        <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
            <FieldText
                name={`owner_${owner}_business_phone`}
                label={(<Type font="p" color="white">Business Phone:</Type>)}
                value={userData[`owner_${owner}_business_phone`]}
                borderColor={false}
                type="tel"
                size="sm"
                rules={{ phone: true, required: true }}
            />
        </Cell>

        <Cell column={{ default: 'span 1', laptop: 'span 2' }}>
            <FieldText
                name={`owner_${owner}_business_email`}
                label={(<Type font="p" color="white">Business Email Address:</Type>)}
                value={userData[`owner_${owner}_business_email`]}
                borderColor={false}
                type="email"
                size="sm"
                rules={{ email: true, required: true }}
            />
        </Cell>
    </>
);

const FormStep4 = ({
    states,
    onSubmit,
    stepBack,
    userData,
    loading,
    errMsg,
    submitBtnColor,
    submitBtnHoverColor,
    submitBtnTxtColor,
}) => {
    const formRef = useRef();
    const [token, setToken] = useState();
    const [owners, setOwners] = useState(userData.owners_count);
    const { executeRecaptcha } = useGoogleReCaptcha();

    function validate() {
        formRef.current.validate();
    }

    // Create an event handler so you can call the verification on button click event or form submit
    async function handleReCaptchaVerify() {
        if (!executeRecaptcha) {
            return;
        }

        const newToken = await executeRecaptcha();
        setToken(newToken);
    }

    function onChangeOwners(e) {
        const { value } = e.target;
        setOwners(Number(value));
    }

    useEffect(() => {
        if (token) {
            validate();
        }
    }, [token]);

    return (
        <Box>
            {errMsg && (
                <Type tag="h2" font="h3" margin={[0, 0, 'md', 0]} color="white">
                    {errMsg}
                </Type>
            )}
            <ValidationForm ref={formRef} onSubmit={onSubmit} onEnter={handleReCaptchaVerify}>
                <FieldRadio
                    name="owner_count"
                    label={(
                        <Type font="leader" color="white" align="center">
                            How many owners are there of your business:
                        </Type>
                    )}
                    value={userData.owner_count}
                    options={radioOptions[userData.structure === 'partnership' ? 'partnership' : 'main']}
                    margin={[0, 0, 'md', 0]}
                    columns={{
                        default: '1fr 1fr',
                        laptop: userData.structure === 'partnership' ? '1fr 1fr' : '1fr 1fr 1fr',
                    }}
                    area={{
                        default: `
                            'a b'
                            'c c'
                        `,
                        laptop: userData.structure === 'partnership' ? '"a b"' : '"a b c"',
                    }}
                    uncheckedColor="primary200"
                    uncheckedOutline
                    checkedColor="primary400"
                    onChange={onChangeOwners}
                    rules={{ required: true }}
                />

                <Grid column={{ default: '1fr', laptop: '1fr 1fr 1fr 1fr' }} columnGap="md" rowGap="md" mb="xl">
                    {owners && ownerFields(1, userData.structure === 'partnership', userData, states)}
                    {Number(owners) > 1 && ownerFields(
                        2,
                        userData.structure === '537ba7a1-8da4-45be-9572-3d7aac141450',
                        userData,
                        states,
                    )}
                    {Number(owners) > 2 && ownerFields(
                        3,
                        userData.structure === '537ba7a1-8da4-45be-9572-3d7aac141450',
                        userData,
                        states,
                    )}
                </Grid>
                <FieldCheckbox
                    name="optin"
                    value="1"
                    color="white"
                    size="sm"
                    rules={{ required: true }}
                    checked
                    inline={false}
                    label={(
                        <P color="white" font="control" margin={[0, 0, 0, 'sm']}>
                            I certify that I am a U.S. Resident over the age of 18, I agree to the
                            {' '}
                            <Type tag="span" font="control" color="primaryA200" weight="bold">
                                <a href="/privacy-policy" target="_blank">
                                    Privacy Policy
                                </a>
                            </Type>
                            {' '}
                            &
                            {' '}
                            <Type tag="span" font="control" color="primaryA200" weight="bold">
                                <a href="/terms" target="_blank">
                                    Terms of Use
                                </a>
                            </Type>
                        </P>
                    )}
                />
                <HiddenInput name="token" value={token} />
            </ValidationForm>
            <Grid columns={{ default: '1fr 6fr', laptop: '1fr 1fr' }} justify="space-between" align="center">
                <BtnText
                    font="h3"
                    txtColor="primary"
                    outline
                    size="xl"
                    onClick={stepBack}
                >
                    <ArrowLeftCircle size="32" />
                </BtnText>

                <Btn
                    font="h3"
                    type="button"
                    color={submitBtnColor}
                    hoverColor={submitBtnHoverColor}
                    txtColor={submitBtnTxtColor}
                    size="lg"
                    iconRight={(<ArrowRightCircle size="32" />)}
                    onClick={handleReCaptchaVerify}
                    loading={loading}
                    iconLoading={(<Loader size="32" />)}
                >
                    CONTINUE
                </Btn>
            </Grid>
        </Box>
    );
};

FormStep4.propTypes = {
    onSubmit: PropTypes.func,
    stepBack: PropTypes.func,
    userData: PropTypes.object,
    loading: PropTypes.bool,
    errMsg: PropTypes.string,
    submitBtnColor: PropTypes.string,
    submitBtnHoverColor: PropTypes.string,
    submitBtnTxtColor: PropTypes.string,
    states: PropTypes.object,
};

FormStep4.defaultProps = {
    userData: {},
};

export default FormStep4;
