import React from 'react';
import {
    Box, Type, Grid, Container,
} from '@saladbob/sassafras';

const scrollMargin = { scrollMarginTop: 100 };

const Faq = () => (
    <Box align="center" padding="xl">
        <Container size="md" align="center" margin="auto">
            <Grid columns="1fr" rowGap="md">
                <Type tag="h1" font="h1" id="top">
                    Have questions about Green Financial?
                </Type>
                <Type tag="h2" font="h4" weight="lighter">
                    Check out our FAQs for more information about how Green Financial works.
                </Type>
                <Type tag="h3" font="h3" id="q1" style={scrollMargin}>
                    How long does it take to get approved?
                </Type>
                <Type tag="p">
                    As long as you meet the eligibility criteria, Green Financial can provide same day approvals for
                    funding $5,000 - $250,000 upon verifying your identity and bank account. In some situations,
                    we may need to perform additional steps to confirm your identity or verify your bank account.
                    If an extensive review of documents is required, it will take longer to provide you with a decision.
                </Type>

                <Type tag="h3" font="h3" id="q2" style={scrollMargin}>
                    What is Same Day Funding?
                </Type>
                <Type tag="p">
                    Same Day Funding is as simple as it sounds. It allows you to receive funds in your account as
                    early as the same business day you finalize your funding with Green Financial (as long as we have
                    not passed the bank’s same day cut-off window for electronic fund transfer). Ask your Funding
                    Specialist for more details.
                </Type>

                <Type tag="h3" font="h3" id="q3" style={scrollMargin}>
                    Is this program a loan product?
                </Type>
                <Type tag="p">
                    No, this is not a loan product. Our program is a merchant cash advance (MCA). It&lsquo;s a
                    commercial agreement where the business owner sells their future credit card sales or other
                    business receipts to Green Financial. Our product does not have a fixed repayment term and is
                    repaid through smaller, regular (daily or weekly) payments, versus monthly payments with a fixed
                    repayment term. Your payments may be adjusted over time to be more in-line with your sales.
                </Type>

                <Type tag="h3" font="h3" id="q4" style={scrollMargin}>
                    Will filling out a pre-qualification request affect my credit score?
                </Type>
                <Type tag="p">
                    No. The pre-qualification request is based on a “soft pull” which does not negatively impact your
                    credit score.
                </Type>

                <Type tag="h3" font="h3" id="q5" style={scrollMargin}>
                    How does payback work?
                </Type>
                <Type tag="p">
                    Paying back your funding with Green Financial is easy and hassle-free with automatic bank account
                    deductions so you don’t have to worry about missing a payment. The daily or weekly payment is
                    based on the personalized purchase of future receivable offer that our underwriting team has
                    created for your business. The payment is intended to be a manageable amount based on your
                    business’ cash flow.
                </Type>

                <Type tag="h3" font="h3" id="q6" style={scrollMargin}>
                    How much will it cost?
                </Type>
                <Type tag="p">
                    Green Financial’s product is not a loan, and therefore our product has no interest rate. The cost
                    of your funding is based on the discount we apply to the amount of future receivables we offer to
                    purchase.  This discount may vary based on a number of factors, including your personal and
                    business credit scores, your financial history, time in business, deposits, and cash flow.
                </Type>

                <Type tag="h3" font="h3" id="q7" style={scrollMargin}>
                    Can I get approved if I have bad credit?
                </Type>
                <Type tag="p">
                    Green Financial determines the approval amount on your overall cash flow, not your credit score.
                    Having a bad credit score doesn’t mean you can’t qualify for our product. Our programs can provide
                    an alternative method of funding for business owners who have had difficulty getting approved for
                    traditional programs.
                </Type>

                <Type tag="h3" font="h3" id="q8" style={scrollMargin}>
                    Who is Green Financial?
                </Type>
                <Type tag="p">
                    Green Financial helps small and medium-sized businesses obtain funding. We strive to be a trusted
                    and committed partner to our customers, and we make the funding experience affordable, simple, and
                    transparent. Unlike traditional providers, we cater exclusively to small and medium-sized
                    businesses, which often find it difficult to get traditional financing. Many online funding
                    providers are heavily automated, so you never get to speak to a real person. Green Financial’s
                    underwriting team carefully looks at the individual characteristic on your business.  We work
                    directly with our customers to give that human touch, and to get the right-sized funding for
                    their unique needs.
                </Type>

                <Type tag="h3" font="h3" id="q9" style={scrollMargin}>
                    My funding was satisfied, but I believe some extra charges came through. What should I do?
                </Type>
                <Type tag="p">
                    We’re sorry for any inconvenience. It can take up to five business days for a payment to settle
                    into our account, and if we take any additional payments during that time, we will process a
                    refund. We process refunds each Friday and you should receive them early the following week.
                </Type>
            </Grid>
        </Container>
    </Box>
);

export default Faq;
