import React from 'react';
import PropTypes from 'prop-types';
import {
    H2,
    H3,
    P,
} from '@saladbob/sassafras';

const Feature = ({
    step, title, disc, align,
}) => (
    <>
        {step && (
            <H2 font="leader" color="secondary600" weight="bold" align={align}>
                Step
                {' '}
                {step}
                .
            </H2>
        )}
        <H3 font="h3" mb="sm" align={align}>
            {title}
        </H3>
        <P font="p" weight="lighter" align={align}>
            {disc}
        </P>
    </>
);

Feature.propTypes = {
    step: PropTypes.string,
    title: PropTypes.string,
    disc: PropTypes.string,
    align: PropTypes.string,
};

Feature.defaultProps = {};

export default Feature;
