import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { createUseStyles } from 'react-jss';
import {
    ThemeContext,
    Box,
    H1,
    P,
    A,
    Strong,
    PanelSteps,
    Spinner,
} from '@saladbob/sassafras';

import FormStep1 from '../components/Forms/FormStep1';
import FormStep2 from '../components/Forms/FormStep2';
import FormStep3 from '../components/Forms/FormStep3';
import FormStep4 from '../components/Forms/FormStep4';

const styles = createUseStyles({
    Apply: {
        display: 'flex',
        width: '100%',
        justifyContent: 'start',
        minHeight: '100%',
        zIndex: 2,
        background: ({ bgColor, theme }) => theme.colors[bgColor] || bgColor,
        transition: 'all ease 0.5s',
        paddingTop: 'max(80px, 10vh)',
    },
    Body: {
        width: '100%',
    },
    '@media (max-width: 869px)': {
        Body: {
            flexWrap: 'wrap',
        },
    },
});

const Apply = ({
    errMsg,
    userData,
    loading,
    onSubmitStep1,
    onSubmitStep2,
    onSubmitStep3,
    onSubmitStep4,
    stepBack,
    formFields,
    onValidate,
    onError,
    onSkip,
    imagePath,
    step,
    path,
    publicPath,
    ...props
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, ...props });

    const submitBtnColor = 'secondary400';
    const submitBtnHoverColor = 'secondary200';
    const submitBtnTxtColor = 'black';

    const steps = [(
        <Box key="form-1" maxWidth="640px" margin="auto">
            <FormStep1
                requestedLoans={formFields.requestedLoans}
                useOfFunds={formFields.useOfFunds}
                userData={userData}
                onSubmit={onSubmitStep1}
                onValidate={onValidate}
                onError={onError}
                loading={loading}
                errMsg={errMsg}
                submitBtnColor={submitBtnColor}
                submitBtnHoverColor={submitBtnHoverColor}
                submitBtnTxtColor={submitBtnTxtColor}
            />
        </Box>
    ), (
        <Box key="form-2" maxWidth="640px" margin="auto">
            <FormStep2
                legalEntities={formFields.legalEntities}
                businessTimes={formFields.businessTimes}
                userData={userData}
                onSubmit={onSubmitStep2}
                onValidate={onValidate}
                onError={onError}
                loading={loading}
                submitBtnColor={submitBtnColor}
                submitBtnHoverColor={submitBtnHoverColor}
                submitBtnTxtColor={submitBtnTxtColor}
                stepBack={stepBack}
            />
        </Box>
    ), (
        <Box key="form-3" maxWidth="640px" margin="auto">
            <FormStep3
                monthlyDeposits={formFields.monthlyDeposits}
                userData={userData}
                onSubmit={onSubmitStep3}
                onValidate={onValidate}
                onError={onError}
                onSkip={onSkip}
                loading={loading}
                submitBtnColor={submitBtnColor}
                submitBtnHoverColor={submitBtnHoverColor}
                submitBtnTxtColor={submitBtnTxtColor}
                stepBack={stepBack}
            />
        </Box>
    ), (
        <Box key="form-4" maxWidth="640px" margin="auto">
            <FormStep4
                states={formFields.states}
                userData={userData}
                onSubmit={onSubmitStep4}
                onValidate={onValidate}
                onError={onError}
                errMsg={errMsg}
                submitBtnColor={submitBtnColor}
                submitBtnHoverColor={submitBtnHoverColor}
                submitBtnTxtColor={submitBtnTxtColor}
                stepBack={stepBack}
            />
        </Box>
    ), (
        <Box
            key="confirmation"
            maxWidth="640px"
            txtColor="white"
            padding={['xxl', 0]}
            height="calc(100vh - 113px)"
            margin="auto"
        >
            <H1 color="white" mb="lg" align="center">
                Thank you for your interest in our Business Funding programs.
            </H1>
            <P font="h3" mb="lg">
                We are reviewing your request, and a member of the
                {' '}
                <Strong>Green Financial</Strong>
                {' '}
                team will be reaching out to you shortly to review your options.
            </P>
            <P>
                If you have immediate questions, please feel free to reach out to us at
                {' '}
                <A href="tel:18885205542" color="primary300">
                    <span style={{ whiteSpace: 'nowrap' }}>
                        1-888-520-5542
                    </span>
                </A>
                {' '}
                or you can
                send a message to
                {' '}
                <A href="mailto:Support@MyGreenFinancial.com" color="primary300">
                    Support@MyGreenFinancial.com

                </A>
                .
            </P>
            <P>
                We look forward to working with you!
                The Green
                Financial Team
            </P>
        </Box>
    ), (
        <Box
            key="loading"
            align="center"
            bgColor="primary"
            padding="xxl"
            verticalAlign="center"
        >
            <Spinner size="xxl" />
        </Box>
    )];

    return (
        <Box bgColor="primary700" className={classes.Apply} padding="lg" margin={[0, 'auto']}>
            <div className={classes.Body}>
                <PanelSteps step={step} travel={64}>
                    {steps}
                </PanelSteps>
            </div>
        </Box>
    );
};

Apply.propTypes = {
    onSubmitStep1: PropTypes.func,
    onSubmitStep2: PropTypes.func,
    onSubmitStep3: PropTypes.func,
    onSubmitStep4: PropTypes.func,
    stepBack: PropTypes.func,
    onValidate: PropTypes.func,
    onError: PropTypes.func,
    onSkip: PropTypes.func,
    imagePath: PropTypes.string,
    publicPath: PropTypes.string,
    step: PropTypes.number,
    path: PropTypes.string,
    loading: PropTypes.bool,
    formFields: PropTypes.object,
    userData: PropTypes.object,
    errMsg: PropTypes.string,
};

Apply.defaultProps = {
    step: 0,
};

export default Apply;
