import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid, Box, Type, Container,
} from '@saladbob/sassafras';

const Footer = ({
    brandName, nav,
}) => (
    <Box bgColor="primary" align="center" padding="md">
        <Container size="lg">
            <Grid
                columns={{
                    default: '1fr',
                    laptop: 'minmax(max-content, max-content) minmax(max-content, max-content)',
                }}
                align="center"
                justify="space-between"
            >
                <Type tag="p" font="small" align="center" color="white" mb="0">
                    &copy; 2020
                    {' '}
                    {brandName}
                    , All Rights Reserved.
                </Type>
                {nav}
            </Grid>
        </Container>
    </Box>
);

Footer.propTypes = {
    brandName: PropTypes.string,
    nav: PropTypes.node,
};

Footer.defaultProps = {};

export default Footer;
